<template>
  <div>
    <v-sheet
      color="info"
      elevation="1"
      rounded
      width="100%"
      dark
    >
      <div
        class="subtitle-1 text-center py-1"
      >
        Tiempo trabajado
      </div>
      <div
        class="text-h4 text-center py-2"
      >
        {{ totales|humanizeDuration }}
      </div>
      <v-btn
        v-if="totales > 0"
        block
        text
        @click.stop="clickDesglose"
      >
        <v-icon
          left
        >
          {{ $vuetify.icons.values.info }}
        </v-icon>Ver detallado
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    totales: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clickDesglose () {
      this.$emit('click-desglose')
    },
  },
}
</script>
